import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"
import Logo from "../../images/logo.png"
import LogoWhite from "../../images/logo-white.png"

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={`header ${styl.header} shadow-sm`}>
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <div class="header-logo">
                <Link to="/">
                  <img
                    className="img-fluid logo-blu"
                    src={Logo}
                    alt="Epiphron Logo"
                  />
                  <img
                    className="img-fluid logo-white"
                    src={LogoWhite}
                    alt="Epiphron Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-3 text-right">
              <a
                href="tel:720-346-8999"
                className="btn-global hvr-outline-out  header-cta"
              >
                <i class="fa fa-phone mr-2" />
                720-346-8999
              </a>
            </div>

            <a
              href="mailto:marie.sherick@epiphronhealthcare.com"
              className="btn-global hvr-outline-out  header-cta"
            >
              <i class="fa fa-envelope mr-3" />
              marie.sherick@epiphronhealthcare.com
            </a>

            <div class="col-1">
              <div class="text-right">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
