import React from "react"
import WebriQForm from "../form/form"
import { Link } from "gatsby"

import LogoWhite from "../../images/logo-white.png"

const footer = ({ location, toggle }) => (
  <div>
    <div
      className="container-fluid padding-100 sub-footer vertical-align"
      id="form"
    >
      <div className="container">
        <div
          className="row vertical-align m-auto
        "
        >
          <div className="col-md-7">
            <img className="w-100" src={LogoWhite} alt="Epiphron Logo" />
            <p className="mt-2 text-white">
              {" "}
              Health care consulting focused on reimbursement, policy and system
              integration based on federal regulations and industry forethought.
              Our team has worked in and around Government health care for
              decades. Our experience includes the Defense Health Agency, the
              World Trade Center Health Program as well as numerous private
              health care consultations.
            </p>
          </div>
          <div className="col-md-3">
            <div className="ml-lg-5">
              <h4 className="mt-2 text-white">Quick Links</h4>
              <ul className="text-white pl-0">
                <li>
                  <Link onClick={toggle} to="/" style={{ color: "#fff" }}>
                    {" "}
                    <i className="fa fa-link mr-2" />
                    Home
                  </Link>
                </li>
                <li>
                  <Link onClick={toggle} to="#about" style={{ color: "#fff" }}>
                    <i className="fa fa-link mr-2" />
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={toggle}
                    to="#services"
                    style={{ color: "#fff" }}
                  >
                    <i className="fa fa-link mr-2" />
                    What We Offer
                  </Link>
                </li>
                {/* <li>
                <Link onClick={toggle} to="/">
                    <i className="fa fa-link mr-2"></i>Client Reviews
                  </Link>
                </li> */}
                <li>
                  <Link onClick={toggle} to="/" style={{ color: "#fff" }}>
                    <i className="fa fa-link mr-2" />
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="col-md-5 contact-clean">
            <WebriQForm
              form_name="Contact Us Form"
              form_id="72115532-bea7-43e0-acc8-72962fff5595"
              thankyou_url="/thank-you"
            >
              <h2 className="text-center mt-0">CONTACT US</h2>
              <p className="text-center" />
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Message"
                  rows="14"
                  required
                />
              </div>
              <div className="form-group">
                <div className="form-group">
                  <div className="webriq-recaptcha" />
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn-global d-block text-center hvr-sweep-to-left btn-form w-100"
                  type="submit"
                >
                  Send Message
                </button>
              </div>
            </WebriQForm>
          </div> */}
        </div>
      </div>
    </div>

    <div className="container-fluid footer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 social-link p-0 w-100">
            {/* <a class="mr-3" href="#">
            <i class="fa fa-facebook"></i>
          </a>
          <a class="mr-3" href="#">
            <i class="fa fa-linkedin"></i>
          </a>
          <a class="mr-3" href="#">
            <i class="fa fa-google-plus"></i>
          </a> */}
          </div>
          <div className="col-md-6">
            <p className="float-sm-right p-0 m-0 footer-stamp text-white">
              © {new Date().getFullYear()} Epiphron. All Rights Reserved. Designed And Powered By 
              <a className="text-white" href="https://www.webriq.com/">
                <span>WebriQ</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default footer
